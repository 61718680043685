// @import "@culturehq/add-to-calendar/dist/styles.css";

// $border-radius:               0 !default;
// $border-radius-lg:            0 !default;
// $border-radius-sm:            0 !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/dropdown";

.chq-atc {
    display: flex;
    position: relative;
    flex-direction: column;
}
  
.chq-atc--button {
    @extend .btn, .btn-block, .btn-primary;
    border-radius: 0;
//     background: transparent;
//     border: 0;
//     border-radius: 5px;
//     box-sizing: border-box;
//     color: #6a89af;
//     cursor: pointer;
//     display: inline;
//     font-family: inherit;
//     font-size: inherit;
//     line-height: inherit;
//     margin: 0;
//     outline: none;
//     padding: 4px 10px;

    svg { display: none; }

    // Dropdown
    & + .chq-atc--dropdown {
        @extend .btn-block;

        width: 100%;
        border: 1px solid $dropdown-border-color;
        
        a {
            @extend .btn, .btn-link;
            display: block;
            text-align: center;
        }
    }
}
  
//   .chq-atc--button:focus {
//     background-color: #eaeaea;
//   }
  
//   .chq-atc--button:hover {
//     background-color: #eaeaea;
//   }
  
//   .chq-atc--button svg {
//     vertical-align: text-bottom;
//   }
  
//   .chq-atc--button path {
//     fill: #6a89af;
//   }
  
//   .chq-atc--dropdown {
//     background-color: white;
//     border-radius: 5px;
//     border: 1px solid #eaeaea;
//     box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
//     box-sizing: border-box;
//     position: absolute;
//     text-align: left;
//     white-space: nowrap;
//     width: 100%;
//     z-index: 1;
//   }
  
//   .chq-atc--dropdown a {
//     color: #6a89af;
//     display: block;
//     padding: 8px 15px;
//     text-decoration: none;
//   }
  
//   .chq-atc--dropdown a:hover {
//     background-color: #eaeaea;
//   }