$letter-spacing: 50px;

.doc-validation-value {
    caret-color: transparent;
    letter-spacing: $letter-spacing;
    font-size: 48px !important;
    text-align: center;
    padding: 0 0 0 30px !important;
    border: none !important;
    line-height: 96px !important;
    
    &:disabled {
        background-color: transparent !important;
    }

    &:focus {
        outline: 0 !important;
        box-shadow: none !important;
        border: none !important;
    }
}